import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import Github from "./Github";
import Aboutcard from "./AboutCard";
import Achievements from "./Achievements";
import Toolstack from "./Toolstack";
import { useTranslation } from 'react-i18next';
import myImg from "../../Assets/my-photo.jpg";
import "./About.css";  // Import the CSS file for media queries

function About() {
  const { t } = useTranslation();

  return (
    <Container fluid className="about-section">
      <Particle />
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col
            md={7}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "50px",
            }}
          >
            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
              {t('about_title')}
            </h1>
            <Aboutcard />
          </Col>
          <Col
            md={5}
            style={{ paddingTop: "120px", paddingBottom: "50px" }}
            className="about-img"
          >
              <img src={myImg} className="img-fluid" alt="avatar"/>
          </Col>
        </Row>
        <h1 className="project-heading">
          {t('bages_and_certificates')}
          { <p style={{ color: "rgb(155 126 172)" }}>{t('click_to_verify')}</p> }
        </h1>
        <Achievements />

        <h1 className="project-heading">
          {t('professional_skillset')}
        </h1>
        <Toolstack />

        <Github />
      </Container>
    </Container>
  );
}

export default About;
