import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import aes from "../../Assets/Projects/aes.png";
import nginx from "../../Assets/Projects/nginx.png";
import vagrant from "../../Assets/Projects/vagrant1.png";
import dockercompose from "../../Assets/Projects/WordPress-creare-site.jpg";
import password from "../../Assets/Projects/password.png";
import jenkins from "../../Assets/Projects/jenkins.png";
import sql_backup from "../../Assets/Projects/backup.png";
import { useTranslation } from 'react-i18next';

function Projects() {
  const { t } = useTranslation();
  
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          {t("projects_my_recent_works")}
        </h1>
        <p style={{ color: "white" }}>
          {t("projects_description")}
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={dockercompose}
              isBlog={false}
              title={t("project_docker_compose_title")}
              description={t("project_docker_compose_description")}
              ghLink="https://github.com/LIcsq/TerraformAWS.git#readme"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={nginx}
              isBlog={false}
              title={t("project_nginx_title")}
              description={t("project_nginx_description")}
              ghLink=""
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={jenkins}
              isBlog={false}
              title={t("project_jenkins_title")}
              description={t("project_jenkins_description")}
              ghLink="https://github.com/LIcsq/Jenkins.git"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={aes}
              isBlog={false}
              title={t("project_phbt_title")}
              description={t("project_phbt_description")}
              ghLink="https://github.com/LIcsq/AES.git"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={sql_backup}
              isBlog={false}
              title={t("project_landing_title")}
              description={t("project_landing_description")}
              ghLink="https://github.com/LIcsq/Sql-backup.git#readme"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={password}
              isBlog={false}
              title={t("project_multipage_title")}
              description={t("project_multipage_description")}
              ghLink="https://github.com/LIcsq/Password-generator.git#readme"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={password}
              isBlog={false}
              title={t("project_calculator_title")}
              description={t("project_calculator_description")}
              ghLink="https://github.com/LIcsq/Password-generator-V2.git#readme"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={vagrant}
              isBlog={false}
              title={t("project_chess_title")}
              description={t("project_chess_description")}
              ghLink="https://github.com/LIcsq/Network-setup.git#readme"
            />
          </Col>

        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
