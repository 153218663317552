import React from "react";
import { Col, Row } from "react-bootstrap";
import awsBadge from "../../Assets/achievements/aws.png";
import Udemy from "../../Assets/achievements/udemy.jpg";
import coursera from "../../Assets/achievements/coursera.svg";
import portaone from "../../Assets/achievements/portaone_logo.jpg";
import awsArhitect from "../../Assets/achievements/architect-associate.png";



function Achievements() {
  return (
     <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <a href="https://www.credly.com/badges/355cc779-9608-4b87-862d-e052b86f97ec/public_url" target="_blank" rel="noopener noreferrer">
          <img src={awsBadge} alt="AWS Badge" style={{ width: "100px", height: "100px" }} />
        </a>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <a href="https://portaonesert.s3.amazonaws.com/Mazur.pdf" target="_blank" rel="noopener noreferrer">
          <img src={portaone} alt="PortaOne Badge" style={{ width: "100px", height: "100px" }}/>
        </a>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <a href="https://www.udemy.com/certificate/UC-bcace226-f9ec-49b6-b3ee-77f15a7e56bb/" target="_blank" rel="noopener noreferrer">
          <img src={Udemy} alt="Udemy Badge" style={{ width: "130px", height: "100px" }}/>
        </a>
      </Col>
         <Col xs={4} md={2} className="tech-icons">
        <a href="https://www.credly.com/badges/bd2b612b-d41b-445f-a7c5-937b7f507cc9/public_url" target="_blank" rel="noopener noreferrer">
          <img src={awsArhitect} alt="awsArhitect" style={{ width: "130px", height: "100px" }}/>
        </a>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <a href="https://coursera.org/share/44064c392ef997389e42f3a273800e40" target="_blank" rel="noopener noreferrer">
          <img src={coursera} alt="Coursera Badge" style={{ width: "150px", height: "110px" }}/>
        </a>
      </Col>
    </Row>
  );
}

export default Achievements;
