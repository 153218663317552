import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { AiFillGithub } from "react-icons/ai";
import { FaLinkedinIn, FaTelegramPlane } from "react-icons/fa";
import { TbMail } from "react-icons/tb";
import { useTranslation } from 'react-i18next';
import backgroundVideo from "../../Assets/video.mp4";

function Home2() {
  const { t } = useTranslation();

  return (
      <Container fluid className="home-about-section" id="about">
        <video autoPlay loop muted id="video-background" playsInline disablePictureInPicture
               controlsList="nodownload noremoteplayback nofullscreen">
          <source src={backgroundVideo} type="video/mp4"/>
        </video>
        <Container>
          <Row>
            <Col md={12} className="home-about-description">
              <h1 style={{fontSize: "2.6em"}}>
                {t('home_introduce_myself')}
                <strong className="main-name"> {t('home_introduce')}</strong>
              </h1>
              <p className="home-about-body">
                {t('home_about_description1')}
                <br/>
                <br/>
                {t('home_about_description2')}
                <br/>
                <br/>
                {t('home_about_description3')}
                <br/>
                <br/>
                {t('home_about_description4')}
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="home-about-social">
              <h1>{t('home_find_me_on')}</h1>

              <ul className="home-about-social-links">
                <li className="social-icons">
                  <a
                      href="mailto:dimamazur989@gmail.com"
                      target="_blank"
                      rel="noreferrer"
                      className="icon-colour  home-social-icons"
                  >
                    <TbMail/>
                  </a>
                </li>
                <li className="social-icons">
                  <a
                      href="https://t.me/Licql"
                      target="_blank"
                      rel="noreferrer"
                      className="icon-colour  home-social-icons"
                  >
                    <FaTelegramPlane/>
                  </a>
                </li>
                <li className="social-icons">
                  <a
                      href="https://www.linkedin.com/in/dmytr%D0%BE-mazur/"
                      target="_blank"
                      rel="noreferrer"
                      className="icon-colour  home-social-icons"
                  >
                    <FaLinkedinIn/>
                  </a>
                </li>
                <li className="social-icons">
                  <a
                      href="https://github.com/LIcsq"
                      target="_blank"
                      rel="noreferrer"
                      className="icon-colour  home-social-icons"
                  >
                    <AiFillGithub/>
                  </a>
                </li>
              </ul>
              <p>
                {t('home_connect_with_me')}
              </p>
            </Col>
          </Row>
        </Container>
      </Container>
  );
}

export default Home2;
